import React from 'react';
//gatsby
import { graphql } from 'gatsby';
//gatsby image
import { GatsbyImage, getImage } from "gatsby-plugin-image";
//router
import { useLocation } from '@reach/router';
//seo
import Seo from 'gatsby-plugin-wpgraphql-seo';
//styles
import styled from 'styled-components';
import { rem } from 'polished';
import vars from 'components/styles/varss';
//components
import Layout from 'components/ui/layout';
import Container from 'components/ui/container';
import Heading from 'components/ui/heading';
import Button from 'components/ui/button';
import Icon from 'components/ui/icon';
//grid
import { Row, Col } from 'styled-bootstrap-grid';
//parser
import parse from "html-react-parser";
//share
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from "react-share";

//styled
const CaseStudyWrap = styled.section`
  
`;

const CaseStudyBack = styled.div`
  position: relative;
  z-index: 3;
  @media (max-width: ${vars.media.smMax}) {
    text-align: center;
    margin-bottom: ${rem('20px')};
  }
  .button {
    svg {
      margin-right: ${rem('10px')};
    }
  }
`;

const CaseStudyHero = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #071A2B;
  color: #fff;
  padding-top: ${rem('60px')};
  padding-bottom: ${rem('40px')};
  margin-bottom: ${rem('100px')};
  .shape {
    position: absolute;
    left: ${rem('-460px')};
    bottom: ${rem('-250px')};
  }
`;

const CaseStudyHeader = styled.div`
  position: relative;
  z-index: 2;
  margin-bottom: ${rem('60px')};
  text-align: center;
  @media (min-width: ${vars.media.mdMin}) {
    margin-top: ${rem('-38px')};
  }
  .case-study {
    font-size: ${rem('24px')};
    color: ${vars.colors.capri};
    font-weight: 800;
    margin-bottom: ${rem('20px')};
  }
  .date {
    margin-bottom: ${rem('20px')};
  }
  .image-socials {
    position: relative;
  }
  .img-wrap {
    height: 520px;
    background-color: ${vars.colors.grey1};
    border-radius: ${rem('12px')};
    overflow: hidden;
    margin-bottom: ${rem('32px')};
    .gatsby-image-wrapper {
      display: block;
    }
    img {
      width: 101%;
      height: 520px;
      border-radius: ${rem('12px')};
      object-fit: cover;
      object-position: center;
      transform: translateZ(0)!important;
    }
    .logo {
      background-color: #fff;
      padding: ${rem('16px')} ${rem('20px')};
      border-radius: 12px;
      position: absolute;
      top: ${rem('24px')};
      left: ${rem('24px')};
      z-index: 2;
      max-width: ${rem('200px')};
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .social-share {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: ${vars.media.xlMin}) {
      flex-direction: column;
      position: absolute;
      margin: auto;
      top: 0;
      right: ${rem('-117px')};
      bottom: 0;
    }
    li {
      margin-left: ${rem('12px')};
      margin-right: ${rem('12px')};
      @media (min-width: ${vars.media.xlMin}) {
        &:not(:last-child) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: ${rem('28px')};
        }
      }
      a,
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem('24px')};
        height: ${rem('24px')};
        color: #fff;
        svg {
          transition: all .2s ease-in-out;
        }
        &:hover {
          color: ${vars.colors.capri};
          svg {
            fill: ${vars.colors.capri}!important;
          }
        }
      }
    }
  }
`;

const CaseStudyContent = styled.article`
  position: relative;
  padding-bottom: ${rem('60px')};
  .row {
    @media (max-width: ${vars.media.xsMax}) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .card {
    max-width: ${rem('246px')};
    background-color: ${vars.colors.grey1};
    padding: ${rem('28px')};
    border-radius: 12px;
    @media (min-width: ${vars.media.xxlMin}) {
      max-width: ${rem('270px')};
    }
    .logo {
      margin-bottom: ${rem('12px')};
      img {
        max-width: 100%;
        height: auto;
      }
    }
    > p {
      font-size: ${rem('14px')};
      line-height: 1.7;
      margin-bottom: ${rem('16px')};
    }
    .case-study-info {
      .case-study-info-row {
        &:not(:last-child) {
          margin-bottom: ${rem('16px')};
        }
        strong {
          font-size: ${rem('14px')};
          line-height: 1.7;
          margin-bottom: ${rem('4px')};
        }
        span {
          display: block;
          font-size: ${rem('14px')};
          line-height: 1.7;
        }
      }
    }
  }
`;

const CaseStudyShare = styled.div`
  padding: ${rem('28px')} ${rem('32px')} ${rem('8px')} ${rem('32px')};
  background-color: ${vars.colors.grey1};
  border-radius: ${rem('12px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: ${rem('60px')};
  @media (max-width: ${vars.media.xsMax}) {
    margin-bottom: ${rem('30px')};
  }
  h4 {
    margin-right: ${rem('20px')};
  }
  .social-share {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: ${rem('20px')};
    margin-bottom: ${rem('20px')};
    display: flex;
    align-items: center;
    li {
      margin-left: ${rem('12px')};
      margin-right: ${rem('12px')};
      a,
      button {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: ${rem('24px')};
        height: ${rem('24px')};
        svg { 
          transition: all .2s ease-in-out;
        }
        &:hover {
          color: ${vars.colors.capri};
          svg {
            fill: ${vars.colors.capri}!important;
          }
        }
      }
    }
  }
`;

const CaseStudyCTA = styled.div`
  position: relative;
  margin-top: ${rem('100px')};
`;

const CaseStudy = ({ data }) => {

  const study = data.caseStudy;

  //get current url
  const location = useLocation();
  const currentURL = location.href;

  const copyUrl = () => {
    if (typeof window !== `undefined`) {
      const el = document.createElement('input');
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Case Study Link Copied!');
    }
  };

  return (
    <Layout isResources={true}>
      <Seo post={study} />
      <CaseStudyWrap>
        <CaseStudyHero>
          <CaseStudyBack>
            <Container>
              <Button className="button" to="/case-studies" sm>
                <Icon icon="arrow-circle-left" size={18} /> Back To Case Studies
              </Button>
            </Container>
          </CaseStudyBack>
          <svg width={836} height={573} fill="none" xmlns="http://www.w3.org/2000/svg" className="shape"><mask id="a" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x={0} y={0} width={836} height={573}><path fill="#C4C4C4" d="M0 0h836v573H0z" /></mask><g mask="url(#a)"><path d="M309.748 332.545a84.024 84.024 0 0 0 11.261 42.677L410.06 529.41a84.163 84.163 0 0 0 72.87 42.072l178.057.026a84.183 84.183 0 0 0 72.903-42.09l89.025-154.196c15.027-26.028 15.042-58.099 0-84.181l-89.052-154.189c-15.027-26.028-42.815-42.07-72.87-42.071l-34.429.005" stroke="#907AFF" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" /><path d="m626.53 94.765-143.592-.011a84.182 84.182 0 0 0-72.903 42.089L321.009 291.04c-7.414 12.841-11.166 27.152-11.294 41.484m.034.021 228.738.015a58.489 58.489 0 0 0 50.722-29.26l61.941-107.285c10.463-18.123 10.464-40.445-.021-58.557L626.51 94.801" stroke="#fff" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" /><path d="M626.53 94.766 589.194 30.13C578.71 12.018 559.379.858 538.486.854L121.813.862a58.49 58.49 0 0 0-50.722 29.26L9.15 137.408a58.49 58.49 0 0 0 .02 58.556l61.955 107.293c10.485 18.112 29.816 29.273 50.709 29.277l187.915.011" stroke="#1BB3F7" strokeWidth={2} strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" /><path d="M629.983 96.759a3.986 3.986 0 1 1-6.904-3.986 3.986 3.986 0 0 1 6.904 3.986ZM313.201 334.538a3.985 3.985 0 1 1-6.904-3.985 3.985 3.985 0 0 1 6.904 3.985Z" fill="#fff" /></g></svg>
          <CaseStudyHeader>
            <Container maxWidth="848px">
              <p className="case-study">Case Study</p>
              <Heading heading2>{study.title}</Heading>
              <p className="date">{study.date}</p>
              <div className="image-socials">
                <div className="img-wrap">
                  {study.featuredImage &&
                    <GatsbyImage image={getImage(study.featuredImage.node.localFile.childImageSharp)} alt={study.title} />
                  }
                  {study.csMeta.logo && <div className="logo"><img src={study.csMeta.logo} alt="logo" /></div>}
                </div>
                <ul className="social-share">
                  <li>
                    <TwitterShareButton
                      url={`${currentURL}${study.uri}`}
                      title={study.title}>
                      <Icon icon="twitter" size={20} />
                    </TwitterShareButton>
                  </li>
                  <li>
                    <FacebookShareButton
                      url={`${currentURL}${study.uri}`}
                      quote={study.title}>
                      <Icon icon="facebook" size={20} />
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton
                      url={`${currentURL}${study.uri}`}
                      title={study.title}>
                      <Icon icon="linkedin" size={20} />
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <button onClick={copyUrl}>
                      <Icon icon="link" size={20} />
                    </button>
                  </li>
                </ul>
              </div>
            </Container>
          </CaseStudyHeader>
        </CaseStudyHero>
        <CaseStudyContent>
          <Container>
            <Row className="row">
              <Col col xs={12} sm={5} md={3}>
                <div className="card">
                  {study.csMeta.logo && <div className="logo"><img src={study.csMeta.logo} alt="logo" /></div>}
                  <p>{study.csMeta.csOverview}</p>
                  {!!study.csMeta.csInfo && (
                    parse(study.csMeta.csInfo)
                  )}
                </div>
              </Col>
              <Col col xs={12} sm={7} md={9}>
                <div className="wp-front">
                  {!!study.content && (
                    parse(study.content)
                  )}
                </div>
                <CaseStudyShare>
                  <Heading as="h4" heading6 marginBottom="20px">Share this case study</Heading>
                  <ul className="social-share">
                    <li>
                      <TwitterShareButton
                        url={`${currentURL}${study.uri}`}
                        title={study.title}>
                        <Icon icon="twitter" size={20} />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <FacebookShareButton
                        url={`${currentURL}${study.uri}`}
                        quote={study.title}>
                        <Icon icon="facebook" size={20} />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton
                        url={`${currentURL}${study.uri}`}
                        title={study.title}>
                        <Icon icon="linkedin" size={20} />
                      </LinkedinShareButton>
                    </li>
                    <li>
                      <button onClick={copyUrl}>
                        <Icon icon="link" size={20} />
                      </button>
                    </li>
                  </ul>
                </CaseStudyShare>
              </Col>
            </Row>
          </Container>
        </CaseStudyContent>
        <CaseStudyCTA className="wp-front">
          {!!data.wp.blogCtas.caseStudiesCTA && (
            parse(data.wp.blogCtas.caseStudiesCTA)
          )}
        </CaseStudyCTA>
      </CaseStudyWrap>
    </Layout>
  );
};

export const pageQuery = graphql`
query CaseStudyById( $id: String!) {
    caseStudy: wpCaseStudy(id: { eq: $id }) {
      id
      uri
      content
      title
      nodeType
      excerpt
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 826, quality: 100)
            }
          }
        }
      }
      csMeta {
        csInfo
        csOverview
        logo
        quoteName
        quoteRole
        quoteText
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    wp {
      blogCtas {
        caseStudiesCTA
      }
    }
  }
`

export default CaseStudy;